<template>
  <div class="information">
      <div class="infoWraper">
        <h2>{{aboutMeTitle}}</h2>
        <p>{{aboutMeText}}</p>
      </div>
      <div class="infoWraper">
        <h2>{{experienceTitle}}</h2>
        <h3>{{firstExperience}}</h3>
        <p>{{firstLocation}}</p>
        <h3>{{secondExperience}}</h3>
        <p>{{secondLocation}}</p>
        <h3>{{thirdExperience}}</h3>
        <p>{{thirdLocation}}</p>
        <img src="../assets/signature.png"/>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
      aboutMeTitle:String,
      aboutMeText:String,
      experienceTitle:String,
      firstExperience:String,
      firstLocation:String,
      secondExperience:String,
      secondLocation:String,
      thirdExperience:String,
      thirdLocation:String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.information{
    min-height: 100vh;
    background-color: white;
    position: relative;
    margin:0;
    padding:0;
    z-index: 900;
    overflow: hidden;
}
.infoWraper{
    width:40vw;
    margin:5vh 30vw 15vh;
    text-align: left;
}
.infoWraper h2{
    font-size: 44px;
    margin:20px 0 30px;
    color:black
}
.infoWraper h3{
    font-size: 18px;
    margin:10px 0;
    color:black
}
.infoWraper p{
    font-size: 18px;
    margin:0 0 40px
}
.information img{
    width:13vw;
    margin-right: 27vw;
}
@media only screen and (max-width: 500px){
    .infoWraper{
        width:60vw;
        margin:5vh 20vw 15vh;
    }
}
</style>
