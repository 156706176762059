<template>
  <div class="aboutMe">
      <div class="descriptionWrapper">
        <div class="descriptionWrapper2">
          <h1>{{name}}</h1>
          <p>{{description}}</p>
        </div>      
      </div>
  </div>
</template>

<script>
export default {
  name: 'AboutMe',
  props: {
    name: String,
    description: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .aboutMe{
        height:100vh;
        width:100vw;
        background-color: rgb(119, 119, 119);
        background-image: url("../assets/Web-design-package-pricing (1).jpg");
        background-size: cover;
        position: relative;
    }
    .descriptionWrapper{
        width:calc( 100%  );
        height:100%;
        color:white;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .descriptionWrapper2 h1{
      font-size: 24px;
      text-align: left;
    }
    .descriptionWrapper2 p{
      font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
      font-size: 50px;
      width:60vw;
      text-align: left;
      margin:0
    }
    .descriptionWrapper2{
       width:calc( 50% - 100px );
        height:100%;
        color:white;
        background-color: rgba(0, 0, 0, 0.60);
        padding-left: 100px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    @media only screen and (max-width: 500px){
      .descriptionWrapper2 h1{
        display: none;
      }
      .descriptionWrapper2 p{
      font-size: 40px;
      }
    }
    @media only screen and (max-width: 370px){
      .descriptionWrapper2 p{
        font-size: 30px;
      }
    }
</style>
