import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// this.$session.start()
// this.$session.set('dark_mode', false)
// this.$session.set('language', 'fr')

const app = createApp(App);
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;
app.use(router);
app.mount('#app');