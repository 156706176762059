<template>
  <div class="home">
    <Hero 
      heroTitle="Salut, toi."
      msg="👋 Heureux de savoir que tu veux savoir plus sur moi. Tu peux scroller pour avoir plus d'infos ou aller dans mon portfolio!"
      image="true"
    />
    <AboutMe
      name="João Silva"
      description="Je suis un développeur qui aime ce qu'il fait, qui aime innover et essayer des nouvelles choses."
    />
    <Info
      aboutMeTitle="Sur moi"
      aboutMeText="Je suis une personne passionnée qui aime essayer des nouvelles choses. J'ai fait ce site pour avoir un endroit où montrer mes projets privés et peut-être trouver des clients 😃"
      experienceTitle="Expérience"
      firstExperience="ASSISTANT DE RECHERCHE / DÉVELOPPEUR FULLSTACK"
      firstLocation="Institut d'informatique de gestion HES-SO, Sierre"
      secondExperience="DÉVELOPPEUR MOBILE"
      secondLocation="Signant Health, Helsinki"
      thirdExperience="EMPLOYE DE COMMERCE"
      thirdLocation="Linda Photography, Sion"
    />
    <Footer
      facebook="https://www.facebook.com/profile.php?id=100000692160799"
      linkedin="https://www.linkedin.com/in/jcfdsilva/"
      instagram="https://www.instagram.com/jcfdsilva_/"
      email="mailto:contact@joaosilva.ch"
    />
  </div>
</template>

<script>

// @ is an alias to /src
import Hero from '@/components/Hero.vue'
import AboutMe from '@/components/AboutMe.vue'
import Info from '@/components/Info.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Hero,
    AboutMe,
    Info,
    Footer
  },
  // created () {
  //   window.addEventListener('scroll', this.darken);
  // },
  // destroyed () {
  //   window.removeEventListener('scroll', this.darken);
  // },
  // methods: {
  //   darken(event){
  //     var scroll=event.path[1].scrollY;
  //     if(scroll<=700){
  //       var color = parseInt(scroll/5);
  //       var bg = 255-parseInt(scroll/2.9);
  //       var filter = 100-parseInt(scroll/8.14);
  //       if(window.innerWidth>750)
  //         var fontSize = 240-(scroll/50);
  //       else
  //         var fontSize = ((window.width/100)*27)-(scroll/50);
  //       document.getElementById("app").style.backgroundColor="rgb("+bg+", "+bg+", "+bg+")";
  //       document.getElementById("app").style.color="rgb("+color+", "+color+", "+color+")";
  //       document.getElementById("fadeDark").style.filter="brightness("+filter+"%)";
  //       // document.getElementById("heroTitle").style.fontSize=fontSize+"px";
  //       var title = document.querySelectorAll('.heroTitle');
  //       title.forEach(text => {
  //         console.log(text)
  //         text.style.fontSize=fontSize+"px";
  //       })
  //     }
  //   }
  // }
}
</script>

<style>
  #app {
    background-color: rgb(255, 2555, 2555);
    color:rgb(0, 0, 0);
  }
  body{
    margin:0;
    overflow-x: hidden;
  }
</style>