<template>
  <div id="nav">
    <router-link to="/" id="nav-title" v-bind:class="{ active: menuOpen }">João Silva</router-link>
    <div id="screenBlock" v-on:click="menudeactivate" v-bind:class="{ active: menuOpen }"></div>
    <div id="nav-links" v-bind:class="{ active: menuOpen }">
      <router-link v-on:click="menu" to="/" class="nav-link">Home</router-link>
      <router-link v-on:click="menu" to="/portfolio" class="nav-link">Portfolio</router-link>
      <router-link v-on:click="menu" to="/contact" class="nav-link">Contact</router-link>
      <!-- <a class="nav-link" v-on:click="mode()">Black</a> -->
    </div>
    <img v-on:click="menu" id="hamburger" v-bind:class="{ active: menuOpen }" src="./assets/menu.png"/>
  </div>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data : function(){
    return{
      menuOpen:false
    }
  },
  created () {
    window.addEventListener('scroll', this.darken);
  },
  // destroyed () {
  //   window.removeEventListener('scroll', this.darken);
  // },
  methods: {
    darken(event){
      // var scroll=event.path[1].scrollY;
      var scroll=window.scrollY
      if(scroll<=700){
        var color = parseInt(scroll/5);
        var bg = 255-parseInt(scroll/2.9);
        var filter = 100-parseInt(scroll/8.14);
        if(window.innerWidth>750)
          var fontSize = 240-(scroll/50);
        else
          var fontSize = ((window.width/100)*27)-(scroll/50);
        document.getElementById("app").style.backgroundColor="rgb("+bg+", "+bg+", "+bg+")";
        document.getElementById("app").style.color="rgb("+color+", "+color+", "+color+")";
        var image=document.getElementById("fadeDark");
        if(image!=null){
          image.style.filter="brightness("+filter+"%)";
        }
        // document.getElementById("heroTitle").style.fontSize=fontSize+"px";
        var title = document.querySelectorAll('.heroTitle');
        title.forEach(text => {
          text.style.fontSize=fontSize+"px";
        })
      }
    },
    menu : function(event){
      this.menuOpen=!this.menuOpen
    },
    menudeactivate : function(event){
      this.menuOpen=false
    }
  }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
  background: rgba(0,0,0,0);
}

#nav a {
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color:black
}

#nav-links a.router-link-exact-active {
  font-weight: 500;
  text-decoration: underline;
  color:black
}
#nav #nav-title{
  font-size:35px;
}
.nav-link{
  margin:10px
}
#hamburger{
  display:none;
  z-index: 1000;
}
@media only screen and (max-width: 750px){
  #nav-links{
    position: fixed;
    right:-200vw;
    top:0;
    background-color: rgb(255, 255, 255);
    color:rgb(37, 37, 37);
    display:flex;
    flex-direction: column;
    /* justify-content: center; */
    padding-top:130px;
    align-items: flex-end;
    height:100vh;
    z-index: 999;
    box-shadow: -7px 0px 10px rgba(0, 0, 0, 0.623);
    transition: right 0.3s linear;
  }
  #nav-links.active{
    right:0
  }
  #screenBlock{
    width: 100vw;
    height:100vh;
    z-index: 990;
    background-color: rgb(0, 0, 0);
    opacity: 0;
    position:fixed;
    /* display:none; */
    visibility: hidden;
    top:0;
    left:0;
    transition: opacity .5s;
  }
  #screenBlock.active{
    opacity: .5;
    /* display: block; */
    visibility: visible
  }
  #nav a:not(#nav-title), #nav-links a.router-link-exact-active:not(#nav-title){
    color:rgb(37, 37, 37);
    padding:2vh 100px 2vh 100px
  }
  #hamburger{
    display:block;
    height:37px;
    margin:5px
  }
  #hamburger:hover{
    cursor: pointer;
  }
  #hamburger.active{
    position:fixed;
    right:30px
  }
  .close{
    position: fixed;
    z-index: 1000;
    right:100px;
    top: 33px;
    display:none
  }
  .close.active{
    display:block
  }
}
@media only screen and (max-width: 500px){
  #nav {
    padding: 30px;
  }
  .close{
    right:30px;
  }
}
</style>
