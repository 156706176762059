<template>
  <div class="hero">
    <h1 class="heroTitle">{{heroTitle}}</h1>
    <p>{{msg}}</p>
  </div>
  <img v-if="image !== 'false'" id="fadeDark" class="heroCoffee" src="../assets/coffee.png">
  <div id="introEffect"/>
  <!-- <img class="heroCandy" src="../assets/candy.png"> -->
</template>

<script>
export default {
  name: 'Hero',
  props: {
    heroTitle: String,
    msg: String,
    image:String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#introEffect{
  width:calc( 100vw - 100px );
  height:calc( 100vh );
  background-color: rgba(0,0,0,0);
}
.hero{
  width:calc( 100vw - 100px );
  height:calc( 100vh - 207px );
  padding:100px 0 0 100px;
  position:fixed
}
.hero h1{
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size:240px;
  font-weight: 400;
  line-height: 220px;
  width:20px;
  margin:50px 0
}
.hero p{
  max-width:450px
}
.heroCoffee{
  position: fixed;
  top:40vh;
  right:10vw;
  height:50vh;
}
@media only screen and (max-width: 1200px){
  .heroCoffee{
    display:none 
  }
  .hero h1{
    line-height: 25vw;
  }
}
@media only screen and (max-width: 750px){
  .hero h1{
    font-size:27vw
  }
}
@media only screen and (max-width: 500px){
  .hero{
    padding:100px 0 0 30px;
  }
}
@media only screen and (max-height: 620px){
  .hero{
    padding:0px 0 0 30px;
  }
}
@media only screen and (max-height: 470px){
  .heroTitle{
    margin-top: 0;
  }
}
</style>
