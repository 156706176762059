<template>
  <div class="footer">
      <h3>João Silva</h3>
      <div class="links">
        <a v-bind:href="facebook">Facebook</a>
        <a v-bind:href="linkedin">Linkedin</a>
        <a v-bind:href="instagram">Instagram</a>
        <a v-bind:href="email">Email</a>
      </div>
      <br/>
      <div class="links">
        <a href="/">Home</a>
        <a href="portfolio">Portfolio</a>
        <a href="contact">Contact</a>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    facebook: String,
    linkedin: String,
    instagram: String,
    email:String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
    height:20vh;
    background-color: rgb(237,237,237);
    z-index: 2;
    position: relative;
    padding:30px 100px;
    text-align: left;
    width:calc( 100vw - 200px )
}
.footer h3{
    color:black;
    margin:0;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
}
.links{
    font-size: 13px;
    margin-top: 15px;
    display: inline-block;
}
.links a{
    color:rgb(105, 105, 105);
    width: 100px;
    display: block;
    float:left
}
@media only screen and (max-width: 500px){
  .footer{
    height:100%;
    padding:30px;
    width:calc( 100vw - 60px )
  }
  .links a{
    width: 80px;
  }
}
</style>